import React, { useEffect, useState } from "react";
import "./Home.css";
import HomeBannerImg from "../../../Assets/Images/HomeBanner.png";
import HomeBannerPhoneImg from "../../../Assets/Images/HomeBannerPhone.jpg";
import HomeAboutUs from "../../../Assets/Images/HomeAboutUs.jpg";
import service1 from "../../../Assets/Images/Software Engineering.svg";
import service2 from "../../../Assets/Images/API Design.png";
import service3 from "../../../Assets/Images/Power BI.png";
import service4 from "../../../Assets/Images/Strategic Architecture.svg";
import service5 from "../../../Assets/Images/uiux.svg";
import service6 from "../../../Assets/Images/Legacy System.svg";
import cardRefund from "../../../Assets/Images/cardRefund.svg";
import clock from "../../../Assets/Images/clock.svg";
import ContactFormShort from "../../Shared/ContactFormShort/ContactFormShort";
import { Link } from "react-router-dom";
import TypingEffect from "../../Shared/TypingEffect/TypingEffect";
import SlideAnimation from "../../Shared/SlidesAnimation/SlideAnimation";
import LazyLoadContainer from "../../Shared/LazyLoadContainer/LazyLoadContainer";

function Home() {
  const handleScroll = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };
  const [isWide, setIsWide] = useState(window.innerWidth >= 1125);

  useEffect(() => {
    const handleResize = () => {
      setIsWide(window.innerWidth >= 1125);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const aboutUsStyle = {
    backgroundImage: isWide ? `url(${HomeAboutUs})` : "none",
  };

  const homeBannerStyle = isWide ? `url(${HomeBannerImg})` : "none";
  const slides = [
    <div key="1" className="siteOffers">
      <h4>Money Back Offer</h4>
      <p>
        We offer a full refund if you are not satisfied with the initially
        agreed delivery of your project
      </p>
    </div>,
    <div key="2" className="siteOffers">
      <h4>Free Consultation</h4>
      <p>
        Non-binding consultations and proposals from our experts for your
        business ideas
      </p>
    </div>,
  ];
  return (
    <main>
      <div className="container">
        <div className="banner">
          {isWide ? (
            <>
              <LazyLoadContainer
                src={isWide ? HomeBannerImg : "none"}
                placeholder="none"
                className="background-container"
                style={{
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100%",
                  height: "80rem",
                }}
              >
                <div className="bannerContent">
                  <div className="bannerLeft">
                    <div className="bannerText">
                      <h1>
                        <span>IT Solutions</span>
                        <TypingEffect
                          texts={[
                            "to Drive Your Business Forward",
                            "for Staying One Step Ahead",
                          ]}
                          speed={100}
                          delay={3000}
                        />
                      </h1>
                      <button className="primaryBtn" onClick={handleScroll}>
                        CONTACT US
                      </button>

                      <div className="offerSlides">
                        <SlideAnimation slides={slides} interval={6000} />
                      </div>
                    </div>
                  </div>
                </div>
              </LazyLoadContainer>
            </>
          ) : (
            <>
              <div className="bannerContent">
                <div className="bannerLeft">
                  <div className="bannerText">
                    <h1>
                      <span>IT Solutions </span> <br />
                      to Drive Your
                      <TypingEffect
                        texts={["Business Forward"]}
                        speed={100}
                        delay={3000}
                      />
                    </h1>
                    <div className="offerSlides">
                      <SlideAnimation slides={slides} interval={6000} />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="aboutUsRapper">
          <div className="aboutUs" style={aboutUsStyle}>
            <div className="aboutText">
              <h1>
                About <span>Our Company</span>
              </h1>
              <p>
                At Klug Infotech, our clients are our partners. We ensure
                transparency and build trust by keeping you informed and
                involved, with regular updates and inspections throughout our
                collaboration. Klug is a team of experienced and creative IT
                minds offering web design, development, system engineering, and
                more IT services. <br></br> <br></br>
                Our mission is to deeply understand your needs and provide
                tailored IT services aligned with your goals. Klug is not just a
                company but a trusted partner who guides you toward achieving
                your dreams. Let's turn your vision into reality and ensure your
                success together.
              </p>
              <Link to="/about">
                <button className="primaryBtn">Know More</button>
              </Link>
            </div>
          </div>
        </div>

        <div className="whyKlug">
          <div className="whyKlugTextLeft">
            <h1>Why Klug Infotech</h1>
            <p>
              We make your software journey better with our efficient and
              trustworthy approach. We prioritize commitment, reliability, and
              effective collaboration to ensure your projects are delivered
              promptly and professionally. Whether you're a startup or an
              established brand, we adapt our approach to meet your needs. Our
              agile mindset means we're open to address queries and accommodate
              changes as needed.
              <span>Transparency and Honest Communication</span>
              We believe in open communication, acting with integrity,
              understanding your problems, and ensuring your success. We keep
              you updated at every step, ensuring transparency and trust
              throughout our collaboration. Our commitment is to deliver results
              you can count on, with no surprises.
            </p>
            <Link to="/whyUs">
              <button className="primaryBtn">Know More</button>
            </Link>
          </div>
          <div className="whyKlugTextRight">
            <div className="whyKlug-right">
              <div className="whyKlug-title">
                <h3>Klug Mantra</h3>
              </div>
              <div className="offer1">
                <h5>
                  <span>K</span>eeping Transparency
                </h5>
                <p>
                  We keep things transparent with our partners. You'll always
                  know what's up with your project. No unexpected changes, just
                  consistent updates and transparent communication.
                </p>
              </div>
              <div className="offer2">
                <h5>
                  <span>L</span>eading with Integrity
                </h5>
                <p>
                  We hold ourselves to the highest work ethics. Mutual trust and
                  honesty are the foundation of our work. Ensuring your success
                  is our primary objective.
                </p>
              </div>
              <div className="offer3">
                <h5>
                  <span>U</span>nderstanding Your Needs
                </h5>
                <p>
                  We take the time to deeply understand your business and goals.
                  By adapting our solutions to your needs, we create software
                  that truly fits your vision. Your goals drive us.
                </p>
              </div>
              <div className="offer4">
                <h5>
                  <span>G</span>uaranteeing Success
                </h5>
                <p>
                  We're here to deliver results that wow you. With agile
                  planning, strategic execution, and continuous feedback, we
                  ensure your project is a success. Your happiness is how we
                  measure success.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="homeServices">
          <div className="homeServices-text">
            <h1>
              Our <span>Services</span>
            </h1>
            <Link to="/services">
              <button className="primaryBtn">Know More</button>
            </Link>
          </div>
          <div className="servicePointsHome">
            <div className="service1H">
              <img alt="Service Image" src={service1}></img>
              <h4>Software Engineering</h4>
              <p>
                Software solutions to empower your business to become more
                competitive.
              </p>
            </div>
            <div className="service2H">
              <img alt="Service Image" src={service2}></img>
              <h4>API Design & Communication</h4>
              <p>
                Effective API communication to optimize workflows and improve
                efficiency.
              </p>
            </div>
            <div className="service3H">
              <img alt="Service Image" src={service3}></img>
              <h4>Power BI Solutions </h4>
              <p>
                Intuitive, interactive, customized Power BI solutions to grow
                your business.
              </p>
            </div>
            <div className="service4H">
              <img alt="Service Image" src={service4}></img>
              <h4>Strategic Architecture Planning</h4>
              <p>
                Specialty in scalable, secure solutions, including microservices
                and SOA.
              </p>
            </div>
            <div className="service5H">
              <img alt="Service Image" src={service5}></img>
              <h4>UI /UX Designing</h4>
              <p>
                Unique brand identities created from scratch to bring your
                vision to life.
              </p>
            </div>
            <div className="service6H">
              <img alt="Service Image" src={service6}></img>
              <h4>Legacy System Upgrade</h4>
              <p>
                Upgrade legacy systems to maintain efficiency and stay relevant.
              </p>
            </div>
          </div>
          <Link to="/services" className="servicesBtnResponsive">
            <button className="primaryBtn">Know More</button>
          </Link>
        </div>
        <ContactFormShort />
      </div>
    </main>
  );
}

export default Home;
