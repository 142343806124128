import React from "react";
import "./ContactFormShort.css";
import mailIcon from "../../../Assets/Images/mailIcon.svg";
import phoneIcon from "../../../Assets/Images/phoneicon.svg";
import facebookIcon from "../../../Assets/Images/facebookIcon.svg";
import linkedinIcon from "../../../Assets/Images/linkedinIcon.svg";
import instagramIcon from "../../../Assets/Images/instagramIcon.svg";
import whatsappIcon from "../../../Assets/Images/whatsapp-line.svg";

const recipientEmail = "info@kluginfotech.com";
const emailSubject = "";
const emailBody = "";

const openEmailClient = () => {
  const mailtoUrl = `mailto:${encodeURIComponent(
    recipientEmail
  )}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(
    emailBody
  )}`;
  window.location.href = mailtoUrl;
};

const openWhatsAppChat = (phoneNumber, event) => {
  event.preventDefault();
  const message = encodeURIComponent(
    "Hello, I am interested in your services."
  );
  const url = `https://wa.me/${phoneNumber}?text=${message}`;
  window.open(url);
};

function ContactFormShort() {
  return (
    <section>
      <div className="contactUs">
        <div className="contactText">
          <h1>
            <span>Contact</span> Our Team
          </h1>
        </div>
        <div className="contactDetails">
          <div className="email">
            <div className="title">
              <h4>Email Us At</h4>
            </div>
            <div className="value">
              <a onClick={openEmailClient} className="emailbtn">
                <img alt="mail" src={mailIcon}></img>
                <p>info@kluginfotech.com</p>
              </a>
            </div>
          </div>
          <div className="phone">
            <div className="title">
              <h4>Reach Us On</h4>
            </div>
            <div className="value reachUs">
              <div className="valuePhone">
                <img alt="phoneIcon" src={phoneIcon}></img>
                <p>+91-9317 330 800</p>
              </div>
              <div className="valueWhatsapp">
                <a
                  href="#"
                  onClick={(event) => openWhatsAppChat(919317330800, event)}
                >
                  <img alt="whatsappIcon" src={whatsappIcon}></img>
                </a>
                <a
                  href="#"
                  onClick={(event) => openWhatsAppChat(919317330800, event)}
                >
                  <p>+91-9317 330 800</p>
                </a>
              </div>
            </div>
          </div>
          <div className="social">
            <div className="title">
              <h4>Follow Us On</h4>
            </div>
            <div className="value">
              <a
                href="https://www.facebook.com/profile.php?id=61565614502690&mibextid=ZbWKwL"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt="facebook" src={facebookIcon}></img>
              </a>
              <a
                href="https://www.linkedin.com/company/klug-infotech/"
                onclick="window.location='intent://www.linkedin.com/company/klug-infotech/#Intent;package=com.linkedin.android;scheme=https;end'"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt="linkedin" src={linkedinIcon}></img>
              </a>
              <a
                href="https://www.instagram.com/kluginfotech?igsh=MWZrNXcwbDI3OTFidg=="
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt="instagram" src={instagramIcon}></img>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactFormShort;
